import React from "react";
import { useTitle } from "../../common/utils/title";

const IdentityOauthList: React.FC = () => {
  useTitle("Identities");

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Identities (Oauth)
            </h2>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="p-4">
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
              <p>
                This page intentionally left blank. If you think you have a use
                for this page, please let the backend team know.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IdentityOauthList;
