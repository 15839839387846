import React from "react";
import { Link } from "react-router-dom";
import AccountProfilePic from "../../account/components/profilepic";
import { IdentityOauthSummaryFragment } from "../../graphql/generated";

const IdentityOauthCard: React.FC<{
  identity?: IdentityOauthSummaryFragment;
}> = ({ identity }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        {identity?.account && <AccountProfilePic account={identity?.account} />}
      </div>
      <div className="flex-1 min-w-0">
        <Link
          to={`/identities-oauth/${identity?.id}`}
          className="focus:outline-none"
        >
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm text-gray-500 truncate">{identity?.email} </p>
        </Link>
      </div>
    </div>
  );
};

export default IdentityOauthCard;
