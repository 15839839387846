import React from "react";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { AwsCognitoUserFragment } from "../../graphql/generated";
import { DescriptionList } from "../../common/components/descriptionlist";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import Timestamp from "../../common/components/timestamp";

const IdentityOauthCognitoUser: React.FC<{
  cognitoUser?: AwsCognitoUserFragment;
}> = ({ cognitoUser }) => {
  return (
    <Panel>
      <Panel.Title>AWS Cognito data</Panel.Title>
      <Panel.Body summary>
        <DescriptionList>
          <DescriptionListItem title="Username" value={cognitoUser?.id} />
          <DescriptionListItem
            title="Link"
            value="AWS Cognito"
            link={`https://us-west-2.console.aws.amazon.com/cognito/v2/idp/user-pools/us-west-2_uTU534c2w/user-management/users/details/${cognitoUser?.username}?region=us-west-2`}
          />
          <DescriptionListItem
            title="Enabled"
            value={cognitoUser ? cognitoUser.enabled.toString() : ""}
          />
          <DescriptionListItem
            title="Preferred MFA Setting"
            value={cognitoUser?.preferredMfaSetting}
          />
          <DescriptionListContainer title="User Create Date">
            <Timestamp
              timestamp={cognitoUser?.userCreateDate}
              format="short"
              popover
            />
          </DescriptionListContainer>
          <DescriptionListContainer title="User Last Modified Date">
            <Timestamp
              timestamp={cognitoUser?.userLastModifiedDate}
              format="short"
              popover
            />
          </DescriptionListContainer>
          <DescriptionListItem
            title="User Status"
            value={cognitoUser?.userStatus}
          />
        </DescriptionList>
      </Panel.Body>
      <Panel.Title>Attributes</Panel.Title>
      <Panel.Body>
        <DescriptionList>
          {cognitoUser?.userAttributes?.map((attr) => {
            return (
              <DescriptionListItem
                key={attr.id}
                title={attr.name}
                value={attr.value}
              />
            );
          })}
        </DescriptionList>
      </Panel.Body>
    </Panel>
  );
};

export default IdentityOauthCognitoUser;
