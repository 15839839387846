import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useIdentityOauthQuery } from "../../graphql/generated";
import IdentityOauthSummary from "../components/summary";
import IdentityOauthTitle from "../components/title";
import { useTitle } from "../../common/utils/title";
import IdentityOauthCognitoUser from "../components/cognito-user";

const IdentityOauthShow: React.FC = () => {
  const params = useParams();

  const [{ data, error }] = useIdentityOauthQuery({
    variables: { id: params.identityId ?? "" },
    pause: !params.identityId,
  });
  useTitle(
    data?.identityOauth.account?.name ?? params.identityId,
    "Identities"
  );

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <IdentityOauthTitle identity={data?.identityOauth} />
          <IdentityOauthSummary identity={data?.identityOauth} />
          {data?.identityOauth.cognitoUser && (
            <IdentityOauthCognitoUser
              cognitoUser={data?.identityOauth.cognitoUser}
            />
          )}
        </div>
      </Transition>
    </>
  );
};

export default IdentityOauthShow;
