import { Menu, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  UserPlusIcon,
  UserMinusIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  AccountFragment,
  IdentityOauthSummaryFragment,
  useIdentityOauthReassociateAccountMutation,
} from "../../graphql/generated";
import AccountPickerDialog from "../../account/components/account-picker-dialog";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";

const IdentityOauthItemActions: React.FC<{
  identity?: IdentityOauthSummaryFragment;
}> = ({ identity }) => {
  const { updateNotification } = React.useContext(NotificationContext);

  const [associateAccountOpen, setAssociateAccountOpen] = React.useState(false);
  const [unassociateAccountOpen, setUnassociateAccountOpen] =
    React.useState(false);

  const [{ fetching: reassociateFetching }, reassociateMutation] =
    useIdentityOauthReassociateAccountMutation();

  const associate = React.useCallback(
    async (account: AccountFragment) => {
      const { error } = await reassociateMutation({
        id: identity?.id ?? "",
        accountId: account.id,
      });

      if (error) {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      } else {
        updateNotification({
          notification: "Account associated",
          notificationType: NotificationType.Success,
        });
      }
      setAssociateAccountOpen(false);
    },
    [identity, updateNotification, setAssociateAccountOpen]
  );

  const unassociate = React.useCallback(async () => {
    const { error } = await reassociateMutation({
      id: identity?.id ?? "",
      accountId: null,
    });

    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    } else {
      updateNotification({
        notification: "Account unassociated",
        notificationType: NotificationType.Success,
      });
    }
    setUnassociateAccountOpen(false);
  }, [identity, updateNotification, setUnassociateAccountOpen]);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item disabled={!!identity?.account}>
                {({ active, disabled }) => (
                  <div
                    onClick={() => setAssociateAccountOpen(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <UserPlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Associate account...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item disabled={!identity?.account}>
                {({ active, disabled }) => (
                  <div
                    onClick={() => setUnassociateAccountOpen(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <UserMinusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Unassociate account...
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <AccountPickerDialog
        isOpen={associateAccountOpen}
        isUpdating={reassociateFetching}
        onAccountPicked={(account: AccountFragment) => {
          associate(account);
        }}
        onCancel={() => setAssociateAccountOpen(false)}
      />

      <ZenDialog
        icon={UserMinusIcon}
        onCancel={() => setUnassociateAccountOpen(false)}
        onSubmit={() => {
          unassociate();
        }}
        title={"Unassociate identity"}
        show={unassociateAccountOpen}
        submit={"Unassociate"}
        state={
          reassociateFetching
            ? ZenDialogState.Submitting
            : ZenDialogState.Displaying
        }
      >
        <p>Are you sure?</p>
      </ZenDialog>
    </>
  );
};

export default IdentityOauthItemActions;
