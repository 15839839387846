import {
  CalendarIcon,
  EnvelopeIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import AccountProfilePic from "../../account/components/profilepic";
import Timestamp from "../../common/components/timestamp";
import { ClipboardContext } from "../../common/context/clipboard";
import { IdentityOauthSummaryFragment } from "../../graphql/generated";

const IdentityOauthTitle: React.FC<{
  identity?: IdentityOauthSummaryFragment;
}> = ({ identity }) => {
  const { sendToClip } = React.useContext(ClipboardContext);
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            {identity?.account && (
              <AccountProfilePic account={identity?.account} large />
            )}
          </div>
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {identity?.id}
            </h2>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <EnvelopeIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                <div
                  className="text-blue-900 cursor-pointer"
                  onClick={() => sendToClip(identity?.email!)}
                >
                  {identity?.email}
                </div>
                <Link
                  to={{
                    pathname: "/subetha/recipients",
                    search: `?email=${identity?.email}`,
                  }}
                >
                  <MagnifyingGlassIcon
                    className="flex-shrink-0 mx-1.5 h-5 w-5 text-zenlist-400"
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                Created:{" "}
                <Timestamp
                  timestamp={identity?.createdAt}
                  format="short"
                  popover
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IdentityOauthTitle;
