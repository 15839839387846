import React from "react";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { IdentityOauthSummaryFragment } from "../../graphql/generated";
import { DescriptionList } from "../../common/components/descriptionlist";
import IdentityOauthItemActions from "./item-actions";

const IdentityOauthSummary: React.FC<{
  identity?: IdentityOauthSummaryFragment;
}> = ({ identity }) => {
  return (
    <Panel>
      <Panel.Title actions={<IdentityOauthItemActions identity={identity} />}>
        Zenlist data
      </Panel.Title>
      <Panel.Body summary>
        <DescriptionList>
          <DescriptionListItem title="Sub" value={identity?.id} />
          <DescriptionListItem title="Email" value={identity?.email} />
          <DescriptionListItem
            title="Account"
            value={identity?.account?.name}
            link={`/accounts/${identity?.account?.id}`}
          />
        </DescriptionList>
      </Panel.Body>
    </Panel>
  );
};

export default IdentityOauthSummary;
