import React from "react";
import { useNavigate } from "react-router-dom";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import { LenderSet, useCreateLenderMutation } from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";

const LenderNew: React.FC = () => {
  useTitle("New", "Lenders");
  const navigate = useNavigate();

  const { updateNotification } = React.useContext(NotificationContext);

  const [firstNameState, setFirstNameState] = React.useState("");
  const [lastNameState, setLastNameState] = React.useState("");
  const [emailState, setEmailState] = React.useState("");
  const [nmlsIdState, setNmlsIdState] = React.useState("");
  const [phoneNumberState, setPhoneNumberState] = React.useState("");
  const [websiteState, setWebsiteState] = React.useState("");
  const [companyNameState, setCompanyNameState] = React.useState("");
  const [lenderSetState, setLenderSetState] = React.useState("");

  const [{ error, data, fetching }, mutation] = useCreateLenderMutation();

  const submitForm = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      mutation({
        details: {
          firstName: firstNameState,
          lastName: lastNameState,
          nmlsId: nmlsIdState,
          email: emailState,
          phoneNumber: phoneNumberState,
          website: websiteState !== "" ? websiteState : undefined,
          companyName: companyNameState !== "" ? companyNameState : undefined,
          lenderSet: lenderSetState !== "" ? lenderSetState  as LenderSet : undefined,
        },
      });
    },
    [emailState, firstNameState, lastNameState, nmlsIdState, phoneNumberState, websiteState, companyNameState, mutation]
  );

  React.useEffect(() => {
    if (!error && data && !fetching) {
      navigate("/lenders");
    } else if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [data, error, fetching, navigate, updateNotification]);

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              New Lender
            </h2>
          </div>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:mx-4 sm:rounded-md">
        <div className="p-4">
          <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={submitForm}
          >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <h3 className="text-xl font-bold leading text-gray-900 sm:truncate">Lender Details</h3>
                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      First Name*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        required
                        type="text"
                        name="first-name"
                        id="first-name"
                        value={firstNameState}
                        onChange={(e) => setFirstNameState(e.target.value)}
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Last Name*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        required
                        type="text"
                        name="last-name"
                        id="last-name"
                        value={lastNameState}
                        onChange={(e) => setLastNameState(e.target.value)}
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="nmls-id"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      NMLS ID*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        required
                        type="text"
                        name="nmls-id"
                        id="nmls-id"
                        value={nmlsIdState}
                        onChange={(e) => setNmlsIdState(e.target.value)}
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Email*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        required
                        type="email"
                        name="email"
                        id="email"
                        value={emailState}
                        onChange={(e) => setEmailState(e.target.value)}
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="phone-number"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Phone Number*
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="phone-number"
                        id="phone-number"
                        value={phoneNumberState}
                        onChange={(e) => setPhoneNumberState(e.target.value)}
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="lender-set"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Lender Set (filtering purposes)
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                        id="mls"
                        name="mls"
                        value={lenderSetState}
                        onChange={(e) => setLenderSetState(e.target.value)}
                        className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      >
                        <option value={""}>Please Select</option>
                        <option value={LenderSet.KeyMortgage}>Key Mortgage</option>
                        <option value={LenderSet.Zenlist}>Zenlist</option>
                      </select>
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="company-name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Company Name (internal display only)
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        required
                        type="text"
                        name="company-name"
                        id="company-name"
                        value={companyNameState}
                        onChange={(e) => setCompanyNameState(e.target.value)}
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="website"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Website
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="website"
                        id="website"
                        value={websiteState}
                        onChange={(e) => setWebsiteState(e.target.value)}
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
                <button
                  //   disabled={fetching}
                  type="submit"
                  className={classNames(
                    // eslint-disable-next-line no-constant-condition
                    true
                      ? "text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      : "text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                    "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md"
                  )}
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LenderNew;
